body.input-stage .app {
  max-width: 700px;
}

body.results-stage .app {
  max-width: 1200px;
}

.input-area-wrapper {
  width: 90%;
  max-width: 550px;
  margin: 0 auto;
}

textarea.input-area.form-control {
  height: 475px;
}

.bottom {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
}

.decrease-reserve-label {
  grid-column: 1 / span 2;
}

.main-button {
  grid-column-start: 3;
}

@media (max-width: 580px) {
  .bottom {
    grid-template-columns: repeat(2, 1fr);
  }

  .decrease-reserve-label {
    grid-column-end: 2;
  }

  .main-button {
    grid-column-start: 2;
    margin: 0 auto;
  }

  .app {
    margin-bottom: 15px;
  }
}

@media (max-width: 430px) {
  .bottom {
    grid-template-columns: 1fr;
  }

  .bottom > label {
    margin: 0 20%
  }

  .main-button {
    grid-column-start: 1;
    width: 80%;
  }
}

@media (max-width: 400px) {
  .app {
    width: 95%;
    padding: 10px;
  }

  .input-area-wrapper {
    width: 95%;
    margin: 0 2.5%;
  }

  .bottom {
    margin-top: 10px;
  }
}

@media (max-height: 715px) {
  textarea.input-area.form-control {
    height: 64vh;
  }

  .bottom {
    margin-top: 10px;
  }
}

@media (max-height: 615px) {
  textarea.input-area.form-control {
    height: 61vh;
  }
}

@media (max-height: 550px) {
  textarea.input-area.form-control {
    height: 58vh;
  }
}

@media (max-height: 490px) {
  textarea.input-area.form-control {
    height: 56vh;
  }
}

@media (max-height: 440px) {
  textarea.input-area.form-control {
    height: 51vh;
  }

  .bottom {
    margin-top: 5px;
  }
}

@media (max-height: 400px) {
  textarea.input-area.form-control {
    height: 48vh;
  }
}

@media (max-height: 350px) {
  textarea.input-area.form-control {
    height: 43vh;
  }

  .bottom {
    margin-top: 2px;
  }
}

.swal-button-abort {
  background-color: red;
}

.swal-button-abort:hover,
.swal-button-abort:active {
  background-color: darkred !important;
}

.swal-button-warning {
  background-color: #ffcb71;
  color: #313131;
}

.swal-button-warning:hover,
.swal-button-warning:active {
  background-color: #d2a048 !important;
}
