.menu {
  min-width: 230px;
  max-width: 250px;
  width: 15%;
  position: fixed;
  background-color: #5e60b9;
  border-radius: 0 0 15px 0;
  font-size: 1.1rem;
  padding: 15px;
}

.menu ul {
  list-style: none;
}

.menu li {
  padding-bottom: 5px;
  cursor: pointer;
  color: #dedede;
  transition-duration: 250ms;
}

.menu li:hover,
.menu li:active {
  color: white;
}

.menu a,
.menu a:visited {
  color: #dedede;
  text-decoration: none;
}

.menu a:hover,
.menu a:active {
  color: white;
  text-decoration: none;
}

.mobile-menu-handler {
  display: none;
}

@media (max-width: 1180px) {
  body.input-stage > div.app {
    margin-top: 50px;
  }

  .menu {
    display: none;
    width: 100%;
    max-width: none;
    text-align: center;
    font-size: 1.2rem;
    z-index: 2;
    border-radius: 0 0 15px 15px;
  }

  .mobile-menu-handler {
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    font-size: 1.75em;
    height: 50px;
    right: 0;
    top: 0;
    left: 0;
    box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.75);
    border-radius: 0;
    background-color: #5e5fb9;
    transition-duration: 250ms;
    z-index: 3;
  }

  .menu {
    top: 0;
  }

  .mobile-menu-handler button:focus {
    outline: none;
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background-color: white !important;
  }

  .hamburger {
    padding: 8px 12px;
  }

  body.menu-open {
    overflow: hidden;
  }

  body.menu-open .menu {
    display: block;
  }

  body.menu-open .menu > ul {
    margin-top: 65px;
  }

  body.menu-open .mobile-menu-handler {
    box-shadow: none;
  }
}

@media (max-width: 480px) {
  .input-area {
    padding-right: 50px;
  }
}

body.results-stage .mobile-menu-handler,
body.results-stage .menu {
  display: none;
}
