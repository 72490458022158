* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #e9f1ff !important;
}

.app {
  width: 90%;
  margin: 0 auto;
  background-color: white;
  border-radius: 0 0 20px 20px;
  padding: 20px;
}

.main-button {
  width: 177px;
  font-size: large;
  padding: 7px 25px;
  background-color: #5d5de3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 250ms;
}

.main-button:hover,
.main-button:active {
  background-color: #0d37fa;
}

.main-button:disabled {
  cursor: default;
  background-color: #b6b8e6;
}
