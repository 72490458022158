.cool-swal-label {
  display: block;
  margin-top: 10px;
}

.cool-swal-label:last-of-type {
  margin-bottom: 10px;
}

.cool-swal-label > .swal-number-input {
  width: 100px;
}

.swal-number-input {
  padding: 0.2rem 0.35rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
